<template>
  <v-app :style="colorVapp">
    <onebox_toolbar_inboxoutbox
      :parentfolder="parentfolder"
      @loadfile="loadfolder()"
      @callparentfolder="getSourceSystem()"
      @callstorage="loadstorage()"
    ></onebox_toolbar_inboxoutbox>
    <v-content>
      <!-- <v-divider></v-divider> -->
      <v-overlay :value="processloader" absolute :color="color.BG" dark>
        <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
        <br />
        <br />
        <span :style="colorProgress">&nbsp; &nbsp; loading</span>
      </v-overlay>
      <v-overlay :value="loaddataprogress_print" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>

      <v-list nav :color="color.BG">
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="20">developer_board</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span
                :style="headerPage"
              >{{ $t('allbusinessPage.inbox.title') + ' ('+ Labeldata +")" }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card class="elevation-0" :color="color.BG" id="thiscontainer_allbusinessinboxsystem">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-expansion-panels v-model="panel" multiple outlined>
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <v-layout row wrap align-center>
                        <v-flex lg2 xs6>
                          <span :style="headerPage">{{ $t('dcmPage.conditionFile')}}</span>
                        </v-flex>
                        <v-flex lg2 xs6>
                          <v-select
                            @change="searchdata"
                            outlined
                            dense
                            hide-details
                            return-object
                            v-model="datatype"
                            :items="listdatatype"
                            item-value="id"
                            item-text="type"
                            :color="color.theme"
                            :item-color="color.theme"
                            :disabled="checkloaddata"
                          >
                            <template v-slot:item="{ item }" :color="color.theme">{{$t(item.type)}}</template>
                            <template
                              v-slot:selection="{ item }"
                              :color="color.theme"
                            >{{$t(item.type)}}</template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกชื่อไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="filename"
                                    :label="$t('myinboxPage.conditionOptional.filename')"
                                    prepend-icon="insert_drive_file"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <!-- ช่องเลือกประเภทไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="fileextension"
                                    :items="listExtensionFile"
                                    item-value="data_type"
                                    item-text="data_type"
                                    :label="$t('myinboxPage.conditionOptional.fileextension')"
                                    prepend-icon="extension"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    multiple
                                  >
                                    <template v-slot:prepend-item>
                                      <v-list-item
                                        ripple
                                        @click="fn_selectall_fileextension"
                                      >
                                        <v-list-item-action>
                                          <v-icon :color="fileextension.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icons }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="index < maxDisplay">{{ item.data_type }}</span>
                                      <span
                                        v-if="index === maxDisplay"
                                        class="grey--text caption"
                                      >(+{{ fileextension.length - maxDisplay }} )</span>
                                    </template>
                                  </v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdateupload"
                                    v-model="modalFromdateupload"
                                    :return-value.sync="fromdateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdateupload()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdateupload = false"
                                      >{{$t('myinboxPage.conditionOptional.closefromdateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodateupload"
                                    v-model="modalTodateupload"
                                    :return-value.sync="todateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodateupload.save(todateupload)"
                                      :min="fromdateupload"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodateupload = false"
                                      >{{$t('myinboxPage.conditionOptional.closetodateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <!-- ช่องกรอก ผู้ส่ง -->
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sendername"
                                    :label="$t('myinboxPage.conditionOptional.sendername_')"
                                    prepend-icon="account_box"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                 <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="receivername"
                                    :label="$t('myinboxPage.conditionOptional.receivername_')"
                                    prepend-icon="account_circle"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                
                              </v-layout>
                              <!-- ช่องกรอก ผู้ส่ง -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <!-- ช่องเลือก ประเภเอกสาร -->
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documenttype"
                                    :items="listDoctype"
                                    item-value="document_type_code"
                                    :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                    :label="$t('myinboxPage.conditionOptional.doctype')"
                                    prepend-icon="line_style"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    multiple
                                  >
                                  <template v-slot:prepend-item>
                                      <v-list-item
                                        ripple
                                        @click="fn_selectall"
                                      >
                                        <v-list-item-action>
                                          <v-icon :color="documenttype.length > 0 ? 'indigo darken-4' : ''">
                                            {{icon}}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="index < maxDisplay">{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }}  , </span>
                                      <span
                                        v-if="index === maxDisplay"
                                        class="grey--text caption"
                                      >(+{{ documenttype.length - maxDisplay }} ประเภท)</span>
                                    </template>
                                  </v-select>
                                </v-flex>

                                <!-- สถานะขอคืนภาษี -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="statusetax"
                                    :items="liststatusetax"
                                    item-value="statusetax_value"
                                    item-text="statusetax_type"
                                    label="สถานะขอคืนภาษี"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    multiple
                                  >
                                    <template v-slot:prepend-item>
                                      <v-list-item ripple @click="fn_selectall_statusetax">
                                        <v-list-item-action>
                                          <v-icon :color="statusetax.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon_ }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                     <!-- <template v-slot:selection="{ item, index }">
                                      <span v-if="index < maxDisplay">{{ item.statusetax_type }} , </span>
                                      <span
                                        v-if="index === maxDisplay"
                                        class="grey--text caption"
                                      >(+{{ statusetax.length - maxDisplay }})</span>
                                    </template> -->
                                  </v-select>
                                </v-flex>
                               
                                <!-- chanel -->
                                <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myinboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex> -->
                              </v-layout>
                            </v-container>
                          </v-flex>

                          <v-flex xs12 sm12 md1 lg1 class="text-center">
                            <v-divider vertical></v-divider>
                          </v-flex>

                          <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกเลขที่ใบเสร็จ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentid"
                                    :label="$t('myinboxPage.conditionOptional.filedocumentid')"
                                    prepend-icon="format_list_numbered"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentidto"
                                    :label="$t('myinboxPage.conditionOptional.todateupload')"
                                    prepend-icon="format_list_numbered"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdatedocument()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdatedocument = false"
                                      >{{$t('myinboxPage.conditionOptional.closefromdatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>

                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodatedocument.save(todatedocument)"
                                      :min="fromdatedocument"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodatedocument = false"
                                      >{{$t('myinboxPage.conditionOptional.closetodatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>

                              <!-- ช่องกรอกผู้ขาย Seller-->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellertaxid"
                                    :label="$t('myinboxPage.conditionOptional.sellertaxid_')"
                                    prepend-icon="mdi-domain"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellerbranchid"
                                    :label="$t('myinboxPage.conditionOptional.sellerbranchid_')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>

                              <!-- ช่องกรอกผู้ซื้อ buyer/ receiver-->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyertaxid"
                                    :label="$t('myinboxPage.conditionOptional.buyertaxid_')"
                                    prepend-icon="mdi-domain"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyerbranchid"
                                    :label="$t('myinboxPage.conditionOptional.buyerbranchid_')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>

              <v-flex xs12 lg12 class="text-center">
                <br />
                <v-btn
                    class="ma-1 white--text"
                    :color="color.theme"
                    :disabled="loaddataprogress"
                    :loading="loaddataprogress"
                    @click="Clear_data()"
                      >
                      <v-icon left>mdi-refresh</v-icon>
                        {{ $t("myinboxPage.clear_data") }}
                    </v-btn>
                <v-btn
                  class="ma-1 white--text"
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="searchdata()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t('myinboxPage.search')}}
                </v-btn>
                <v-menu
                :close-on-content-click="closeOnContentClick"
                >
                  <template v-slot:activator="{ on }">
                      <v-btn
                        class="ma-1 white-text"
                        outlined
                      :color="color.theme"
                      :disabled="loaddataprogress"
                      :loading="loaddataprogress"
                        v-on="on"
                        >
                        {{$t('dowloads')}}
                        <v-icon>
                          keyboard_arrow_down
                        </v-icon>
                      </v-btn>
                  </template>
                  <v-list>
                      <v-container fluid>
                      <!-- <v-list-item> -->
                        <v-checkbox v-model="typedownloads" class="mt-0" label="Summary File Duplicate"></v-checkbox>
                        <v-checkbox v-model="typeitem" class="mt-0" label="Summary Item (1 ไฟล์ 1 บรรทัด)"></v-checkbox>
                      <!-- </v-list-item> -->
                      </v-container>
                   <v-list-item 
                    @click="(disableByPackage ? '' : exportfile()),closeOnContentClick = true" 
                    outlined 
                    :disabled="disableByPackage"
                    @mouseover="show_download_all = true"
                    @mouseleave="show_download_all = false"
                   >
                      <v-list-item-title>
                        <v-icon left :color="color.theme">mdi-archive-arrow-down</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.export')}}
                      </v-list-item-title>
                      <v-expand-transition>
                        <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                        </v-overlay>
                      </v-expand-transition>   
                          <template v-if="!disableByPackage">
                              <v-badge v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-27px;left:-205px;"
                                :value="show_download_all"
                                :content="$t('balloon.download_all')"
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge v-else
                                style="z-index:10000;bottom:-27px;left:-205px;"
                                :value="show_download_all"
                                :content="$t('balloon.download_all')"
                                transition="slide-y-transition"
                              ></v-badge>
                            </template>                   
                    </v-list-item>
                    <!-- @click="fn_setheader('csv')" -->
                    <!-- @click="exportfilecsv()" -->
                    <v-list-item 
                      @click="(disableByPackage ? '' : exportfilecsv()),closeOnContentClick = true" 
                      outlined 
                      :disabled="disableByPackage"
                      @mouseover="show_download_all_csv = true"
                      @mouseleave="show_download_all_csv = false"
                      >
                      <v-list-item-title>
                        <v-icon left :color="color.theme">insert_drive_file</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportcsv')}}
                      </v-list-item-title>
                      <v-expand-transition>
                        <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                        </v-overlay>
                      </v-expand-transition> 
                            <template v-if="!disableByPackage">
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-27px;left:-222px;"
                                  :value="show_download_all_csv"
                                  :content="$t('balloon.download_all_csv')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-27px;left:-222px;"
                                  :value="show_download_all_csv"
                                  :content="$t('balloon.download_all_csv')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </template>                     
                    </v-list-item>
                    <!-- @click="fn_setheader('excel')" -->
                    <!-- @click="exportfileexcel()" -->
                    <v-list-item 
                    @click="(disableByPackage ? '' : exportfileexcel()),closeOnContentClick = true" 
                    outlined 
                    :disabled="disableByPackage"
                    @mouseover="show_download_all_excel = true"
                    @mouseleave="show_download_all_excel = false"
                    >
                      <v-list-item-title>
                        <v-icon left :color="color.theme">description</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportexcel')}}
                      </v-list-item-title>
                      <v-expand-transition>
                        <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                        </v-overlay>
                      </v-expand-transition> 
                       <template v-if="!disableByPackage">
                          <v-badge v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-27px;left:-225px;"
                            :value="show_download_all_excel"
                            :content="$t('balloon.download_all_excel')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge v-else
                            style="z-index:10000;bottom:-27px;left:-225px;"
                            :value="show_download_all_excel"
                            :content="$t('balloon.download_all_excel')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </template>                     
                    </v-list-item>
                </v-list>
                </v-menu>
                <!-- <v-btn
                  class="ma-1"
                  :color="color.theme"
                  outlined
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfile()"
                >
                  <v-icon left>mdi-archive-arrow-down</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.export')}}
                </v-btn> -->
                <!-- button csv -->
                <!-- <v-btn
                  class="ma-1"
                  :color="color.theme"
                  outlined
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfilecsv()"
                  v-if="datatype.id != 1 "
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportcsv')}}
                </v-btn> -->
                <!-- <v-btn
                  class="ma-1"
                  :color="color.theme"
                  outlined
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfileexcel()"
                  v-if="datatype.id != 1 "
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportexcel')}}
                </v-btn> -->
              </v-flex>

              <v-flex xs12 lg12>
                <v-layout row wrap class="ma-4">
                  <v-flex xs12 lg2>
                    <v-select
                      class="pr-2"
                      prepend-inner-icon="sort"
                      hide-details
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      item-text="value"
                      item-disabled="disable"
                      @change="clickpagination()"
                      :color="color.theme"
                    >
                    <template v-slot:item="{ item, on, attrs }">                      
                      <v-list-item v-bind="attrs" v-on="on" :item-color="color.theme" :ripple="!item.disable">
                        {{ item.value }}
                      <v-expand-transition>
                        <v-overlay absolute color="grey lighten-1" :value="item.disable">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                        </v-overlay>
                      </v-expand-transition>
                      </v-list-item>
                    </template>                     
                    </v-select>
                  </v-flex>
                  <v-flex xs12 lg2>
                    <!-- เชค datatype.id = 2  ถ้าไม่ใช่ ให้ไม่ขึ้น -->
                    <v-select
                      v-if="datatype.id == '2'"
                      class="pr-2"
                      @change="clickstatus(statusde)"
                      v-model="statusde"
                      hide-details
                      solo
                      dense
                      :items="liststatus"
                    ></v-select>
                  </v-flex>
                  <!-- <v-flex xs12 lg2>
                    <v-btn
                    @click="openreletion=true"
                    :color="color.theme"
                    :dark="color.darkTheme"
                    v-if="datatype.id != 1 "
                    >
                   เพิ่ม relation
                    </v-btn>
                  </v-flex>-->
                  <v-flex
                    lg4
                    offset-lg4
                    class="pl-3 pr-2 hidden-md-and-down"
                    v-if="sortby === 'status'"
                  >
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSortorderby"
                      :color="color.alertSort"
                      dark
                      v-show="clickSortorderby"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon
                          :color="color.alertText"
                          v-if="checkpagination===true"
                        >mdi-chevron-up</v-icon>
                        <v-icon
                          :color="color.alertText"
                          v-else-if="checkpagination===false"
                        >mdi-chevron-down</v-icon>
                        <span :style="colorSort" v-if="sortby === 'status'">{{$t('Filter.status')}}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="closeclicksort"
                        >
                          <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                  <v-flex lg4 offset-lg4 class="pl-3 pr-2 hidden-md-and-down" v-else>
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSort"
                      :color="color.alertSort"
                      dark
                      v-show="clickSort"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon :color="color.alertText" v-if="multiSort===true">mdi-chevron-up</v-icon>
                        <v-icon
                          :color="color.alertText"
                          v-else-if="multiSort===false"
                        >mdi-chevron-down</v-icon>
                        <span :style="colorSort" v-if="sortby === 'name'">{{$t('Filter.nameonly')}}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'docdate'"
                        >{{$t('Filter.docdate')}}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'docno'"
                        >{{$t('Filter.docno')}}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'inboxdate'"
                        >{{$t('Filter.inboxdate')}}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'doctype'"
                        >{{$t('Filter.doctype')}}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'sendname'"
                        >{{$t('Filter.sendnameAll')}}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'receivername'"
                        >{{$t('Filter.receivernameAll')}}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="checkSort"
                        >
                          <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                  <v-flex xs12 offset-lg8 lg2 class="text-xs-center text-right">
                    <!-- <v-chip color="dark-blue">
                      <v-icon left>view_list</v-icon>
                      {{ totalfile + ' ' + $t('myinboxPage.items') || 0 + ' ' + $t('myinboxPage.items')}}
                    </v-chip>
                    <br />-->
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="ma-4">
                  <v-flex xs4 lg4>
                    <div v-if="statusmutipledownload === false" class="pt-2">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        :color="color.theme"
                        :dark="color.darkTheme"
                        rounded
                        class="ma-1"
                        @click="statusmutipledownload = true,show_menu = false"
                      >
                        <v-icon>mdi-menu</v-icon>
                        <p class="ml-2" style="font-size:15px;margin-bottom:2px;">{{$t('balloon.menu')}}</p>
                        <!-- &nbsp;
                        {{ $t('myinboxPage.multipledownload')}}-->
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-40px"
                          :value="show_menu"
                          :content="$t('balloon.menu')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-55px"
                          :value="show_menu"
                          :content="$t('balloon.menu')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <!-- @click="disableByPackage ? '' : multipledownload()"
                        :ripple="!disableByPackage" -->
                      <v-btn
                        @mouseover="show_multipledownload = true"
                        @mouseleave="show_multipledownload = false" 
                        fab 
                        small 
                        color="primary" 
                        class="ma-1" 
                        @click="multipledownload()"
                      >
                      <!-- <v-icon :disabled="disableByPackage">mdi-download-multiple</v-icon> -->
                        <v-icon>mdi-download-multiple</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-30px"
                          :value="show_multipledownload"
                          :content="$t('balloon.download')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px"
                          :value="show_multipledownload"
                          :content="$t('balloon.download')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <!-- <v-expand-transition>
                          <v-overlay absolute color="grey lighten-1" style="border-radius: 50%; width: 100%; height: 160%; margin-top: -7px;" :value="disableByPackage">
                            <v-icon>mdi-lock</v-icon>
                          </v-overlay>
                        </v-expand-transition> -->
                      </v-btn>
                      <v-btn                      
                        @mouseover="show_print = true"
                        @mouseleave="show_print = false"
                        fab
                        small
                        color="#5875D3"
                        class="white--text ma-1 pa-0"
                        @click="fn_printPDF()"
                      >
                        <v-icon>print</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-40px"
                          :value="show_print"
                          :content="$t('balloon.print')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-40px"
                          :value="show_print"
                          :content="$t('balloon.print')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        :color="color.theme"
                        :dark="color.darkTheme"
                        class="ma-1"
                        @click="statusmutipledownload = false, clearmultipledownload(),show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-20px"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs8 lg8 class="text-right">
                    <v-chip :color="color.theme" :text-color="color.chipText" class="ma-2" v-if="openload == true">
                      <v-icon left>mdi-domain</v-icon>
                      {{$t('myinboxPage.loading') + ' ' + loading_company + ' ' + "/" +' ' + total_download + ' ' + $t('myinboxPage.company') || $t('myinboxPage.loading') + ' ' + 0 + ' ' + $t('myinboxPage.company')}}
                    </v-chip>
                    <v-chip :color="color.theme" :text-color="color.chipText" class="ma-2">
                      <v-icon left>view_list</v-icon>
                      {{ totalfile + ' ' + $t('myinboxPage.items') || 0 + ' ' + $t('myinboxPage.items')}}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- Sort File -->
                <!-- <v-flex lg12 xs12>
                <v-layout row wrap class="pl-3 pr-1"   >
                  <v-flex lg8 xs8>
                  </v-flex>
                  <v-flex lg4 offset-lg8 offset-xs4 class="pl-3 pr-0">
                    <v-list nav :color="color.BG" class="py-0">
                      <v-list-item>
                        <v-layout>                
                          <v-flex class="text-right" lg12>
                            <v-menu offset-y rounded="lg">
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn 
                                  small rounded
                                  class="white--text"
                                  :color="color.theme" 
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                <v-icon small>mdi-sort</v-icon>
                                  {{ $t("sorting.sort") }}: 
                                  <div class="font-weight-bold">
                                    {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }}, {{ $t(sorting['direction'].find(x => x.id === direction).text) }}
                                  </div>
                                </v-btn>
                              </template>
                              <v-list dense nav>
                                <v-list-item-group
                                  v-model="sort_by"
                                  active-class="border"
                                  :color="color.theme"
                                >
                                  <template v-for="(item, i) in sorting['sort']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                                <v-divider class="mt-2" />
                                <v-list-item-group
                                  class="mt-4"
                                  v-model="direction"
                                  active-class="border"
                                  :color="color.theme"
                                >
                                  <template v-for="(item, i) in sorting['direction']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                              </v-list>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
                </v-flex> -->

              <v-flex xs12 lg12 class="ma-4" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
              <v-flex xs12 lg12 v-else class="ma-4">
                <div v-if="resolutionScreen <= 500">
                  <!-- {{ filemultipledownload }} -->
                  <div v-if="datatype.id === 2" class="mt-2">
                    <v-list two-line class="mb-6 pb-6 elevation-1" v-if="inboxdata.length !== 0">
                      <v-list-item
                        v-for="item in inboxdata"
                        :key="item.title"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar v-show="statusmutipledownload">
                          <v-checkbox
                            v-model="filemultipledownload"
                            :value="item"
                            v-if="item.trash !=='DELETE'"
                          />
                        </v-list-item-avatar>
                        <v-list-item-avatar>
                          <v-icon
                            large
                            :color="seticon_new(item.data_type)[1]"
                            style="font-weight: 100"
                          >{{ seticon_new(item.data_type)[0]}}</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content @click="previewfile(item)">
                          <v-list-item-title v-text="item.filename"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
                          <div>
                            <v-chip
                              x-small
                              dark
                              :color="item.status === 'pending' ? '#ffcc00':item.status === 'reject' ? 'red':item.status === 'unsend' ? '#ff8533':item.status === 'duplicate'? 'primary':item.status === 'request_delete_log'? '#FF0000':item.status === 'pending_receiver_stoage_full'? '#ffcc00':'success'"
                            >{{item.status === 'request_delete_log' ? 'ขอลบ' :item.status}}</v-chip>
                            <v-icon
                              v-if="item.trash === 'DELETE'"
                              color="red"
                              small
                            >cancel_presentation</v-icon>
                            <v-icon v-else-if="item.trash === 'T'" color="amber" small>delete_sweep</v-icon>
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small outlined v-on="on">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="opendialog = true , newdata=item">
                                <v-list-item-icon>
                                  <v-icon>mdi-information</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myinboxPage.Filedetails')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- @click="opendialogtrue = true, newdata=item" -->
                              <!-- <v-list-item @click="opendialogtrue = true, newdata=item" disabled>
                                <v-list-item-icon>
                                  <v-icon>folder</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myinboxPage.Relateddocument')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-list-item
                                @click="downloadfile(item)"
                                v-if="item.trash === 'T' || item.trash === null"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-download-multiple</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{ $t('dowloads')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                                     <!-- ลบลงถังขยะ -->
                              <!-- <v-list-item
                              @click="fn_deletetotrash(item)"
                              >
                              <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>ลบ</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                                  <!-- <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='N'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                             <!-- <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='Y'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                               <!-- request_delete_log -->
                            <v-list-item
                              v-if="item.status === 'request_delete_log'"
                            >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="pr-2">
                                <v-btn color="green" dark dense @click="opendialogrequestdelete = true , data=item , statusrequestdelete ='Y'">Delete</v-btn>
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-btn
                                  color="red"
                                  dark
                                  dense
                                  @click="deleterequest(item,'N')"
                                >No</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                              <!-- <v-divider  v-if="item.status === 'pending' || item.status === 'reject'"></v-divider> -->
                              <!-- active -->
                              <v-list-item
                                v-if="item.status === 'pending' && item.trash === 'T' || item.status === 'pending' && item.trash === null"
                              >
                                <v-list-item-icon>
                                  <v-icon>insert_drive_file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="pr-2">
                                  <v-btn color="success" dense @click="approvefile(item)">Active</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn
                                    color="red"
                                    dark
                                    outlined
                                    dense
                                    @click="rejectfile(item)"
                                  >Reject</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- pending -->
                              <v-list-item v-if="item.status === 'reject'">
                                <v-list-item-icon>
                                  <v-icon>insert_drive_file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn
                                    color="#ffcc00"
                                    dark
                                    dense
                                    @click="pendingfile(item)"
                                  >Pending</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                v-if="item.status === 'request' && item.trash !== 'DELETE' && item.trash !== 'T' "
                              >
                                <div class="pr-3 pl-2">
                                  <v-btn color="amber" dark @click="btnunsend(item)">Unsend</v-btn>
                                </div>
                                <div>
                                  <v-btn class color="red" dark @click="btnnotapprove(item)">No</v-btn>
                                </div>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                    <v-list v-else class="elevation-1">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <br />
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div>
                  <div v-else class="mt-2">
                    <v-list
                      two-line
                      class="mb-6 pb-6 elevation-1"
                      v-if="paginatedreceiverData.length !== 0"
                    >
                      <v-list-item
                        v-for="item in paginatedreceiverData"
                        :key="item.receiver_name"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar v-show="statusmutipledownload">
                          <v-checkbox
                            v-model="filemultipledownload"
                            :value="item"
                            v-if="item.trash !=='DELETE'"
                          />
                        </v-list-item-avatar>
                        <v-list-item-avatar>
                          <v-icon large :color="color.theme" style="font-weight: 100">business</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="$t('default') === 'th' ? item.receiver_name : item.receiver_name_eng"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                          <v-icon>mdi-download</v-icon>
                          </v-btn>-->
                          <v-chip
                            v-if="item.sum_receive >0"
                            class="ma-2"
                            color="red"
                            text-color="white"
                          >{{ item.sum_receive}}</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list v-else class="elevation-1">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <br />
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else>
                  <!-- Table by Company -->
                  <div v-if="datatype.id === 2">
                    <v-data-table
                      v-model="filemultipledownload"
                      :headers="headers"
                      :items="inboxdata"
                      item-key="file_id"
                      class="elevation-1"
                      hide-default-footer
                      :single-select="false"
                      :show-select="statusmutipledownload"
                      :page.sync="page"
                      :items-per-page="size"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.data_type`]="{ header }">
                        <span
                          :style="headerTable"
                          @click="sortby='status',clickSortorderby=true ,checkpagination=!checkpagination ,check()"
                        >{{header.text}}</span>
                      </template>
                      <template v-slot:[`header.filename`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='name',clickSort=true,multiSort=!multiSort,sortFile(inboxdata) "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_no`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='docno',clickSort=true,multiSort=!multiSort,sortFile(inboxdata)  "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_date`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='docdate', clickSort=true,multiSort=!multiSort,sortFile(inboxdata)  "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_type`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='doctype',clickSort=true,multiSort=!multiSort,sortFile(inboxdata) "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.sender_name`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='sendname',clickSort=true,multiSort=!multiSort,sortFile(inboxdata) "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.receiver_name`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='receivername',clickSort=true,multiSort=!multiSort,sortFile(inboxdata)  "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.cre_dtm`]="{ header }">
                        <span
                          class="pointer"
                          @click="sortby='inboxdate',clickSort=true,multiSort=!multiSort,sortFile(inboxdata)  "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                       <!-- สถานะขอคืนภาษี -->
                    <template v-slot:[`header.tax_refund`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      <!-- <v-icon small>mdi-arrow-down-drop-circle-outline</v-icon> -->
                       <v-select
                        outlined
                        dense
                        v-model="multistatus_etax"
                        label="ตั้งค่าสถานะขอคืนภาษี"
                        :items="liststatusetax"
                        item-value="statusetax_value"
                        item-text="statusetax_type"
                        @change="fn_changestatusetax_multi(inboxdata,multistatus_etax)"
                      ></v-select>
                     
                    </template>
                      <template v-slot:[`header.file_id`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr
                          style="cursor: pointer"
                          
                        >
                        <!-- @contextmenu="rightclickinboxfile($event,props.item)" -->
                          <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox
                              v-model="filemultipledownload"
                              :value="props.item"
                              v-if="props.item.trash !=='DELETE'"
                            />
                          </td>
                          <td width="5%" class="text-center">
                            <div>
                              <v-badge
                                color="red"
                                overlap
                                icon="clear"
                                small
                                v-if="props.item.trash === 'DELETE' "
                              >
                                <v-icon
                                  large
                                  :color="seticon_new(props.item.data_type)[1]"
                                >{{ seticon_new(props.item.data_type)[0]}}</v-icon>
                              </v-badge>
                              <v-badge
                                color="amber"
                                overlap
                                icon="delete_sweep"
                                v-else-if="props.item.trash === 'T' "
                              >
                                <v-icon
                                  large
                                  :color="seticon_new(props.item.data_type)[1]"
                                >{{ seticon_new(props.item.data_type)[0]}}</v-icon>
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="seticon_new(props.item.data_type)[1]"
                              >{{ seticon_new(props.item.data_type)[0]}}</v-icon>
                            </div>
                            <v-chip
                              x-small
                              dark
                              :color="props.item.status === 'pending' ? '#ffcc00':props.item.status === 'reject' ? 'red':props.item.status === 'unsend' ? '#ff8533':props.item.status === 'duplicate'? 'primary':props.item.status === 'request_delete_log'? '#FF0000':props.item.status === 'pending_receiver_stoage_full'? '#ffcc00':'success'"
                            >{{props.item.status === 'request_delete_log'? 'ขอลบ':props.item.status }}</v-chip>
                          </td>
                          <td
                            width="5"
                            :title="props.item.filename"
                          >{{ props.item.filename | subStr}}</td>
                          <td width="10%">{{ props.item.document_no | subStr}}</td>
                          <td
                            width="15%"
                            class="text-center"
                          >{{ setformatdate(props.item.document_date)}}</td>
                          <td width="10%" class="text-center">
                            <v-chip default :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">{{ props.item.document_type }}</v-chip>
                          </td>
                          <td width="10%" class="text-center">
                            <v-chip default :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">{{props.item.sender_name || '-'}}</v-chip>
                          </td>
                          <td width="10%" class="text-center">
                            <v-chip default :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">{{props.item.receiver_name || '-'}}</v-chip>
                          </td>
                          <td
                            width="15%"
                            class="text-center"
                          >{{ formatdatetime(props.item.cre_dtm)}}</td>
                          <td width="15%" class="text-center">
                          <v-select
                            dense
                            outlined
                            class="text-center ma-2"
                            v-model="props.item.tax_refund"
                            :items="liststatusetax"
                            item-value="statusetax_value"
                            item-text="statusetax_type"
                            @change="fn_changestatusetax(props.item, props.item.tax_refund)"
                          ></v-select>
                        </td>
                          <td width="5%" class="text-center">
                            <v-menu>
                              <template v-slot:activator="{ on }">
                                <v-btn class="elevation-0" fab small outlined v-on="on">
                                  <!-- @click="downloadfile(props.item) -->
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="opendialog = true , newdata=props.item">
                                  <v-list-item-icon>
                                    <v-icon>mdi-information</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{$t('myinboxPage.Filedetails')}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <!--@click=" opendialogtrue = true, newdata=props.item "-->
                                <!-- <v-list-item
                                  @click=" opendialogtrue = true, newdata=props.item"
                                  disabled
                                >
                                  <v-list-item-icon>
                                    <v-icon>folder</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{$t('myinboxPage.Relateddocument')}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item> -->
                                <v-list-item
                                  @click="downloadfile(props.item)"
                                  v-if="props.item.trash !== 'DELETE' "
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-download-multiple</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ $t('dowloads')}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- ลบลงถังขยะ -->
                              <!-- <v-list-item
                              @click="fn_deletetotrash(props.item)"
                              >
                              <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>ลบ</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                                   <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true ,data=props.item ,statusdelete='N'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true ,data=props.item ,statusdelete='Y'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-divider v-if="props.item.status === 'request_delete_log'"></v-divider>
                              <v-list-item v-if="props.item.status === 'request_delete_log'">
                              <v-list-item-icon>
                                <v-icon>
                                  delete
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="opendialogrequestdelete = true ,data=props.item,statusrequestdelete='Y'" 
                                  color="green"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Delete
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                                <v-list-item-content class="pl-2">
                                  <v-btn @click="deleterequest(props.item,'N')" 
                                  color="red"
                                  dark
                                  >
                                    <v-list-item-title>
                                        No
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                                <v-divider  v-if="props.item.status === 'pending' || props.item.status === 'reject'"></v-divider>
                                <!-- active -->
                                <v-list-item
                                  v-if="props.item.status === 'pending' && props.item.trash === 'T' || props.item.status === 'pending' && props.item.trash === null"
                                >
                                  <v-list-item-icon>
                                    <v-icon>insert_drive_file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content class="pr-2">
                                    <v-btn
                                      color="success"
                                      dense
                                      @click="approvefile(props.item)"
                                    >Active</v-btn>
                                  </v-list-item-content>
                                  <v-list-item-content>
                                    <v-btn
                                      color="red"
                                      dark
                                      outlined
                                      dense
                                      @click="rejectfile(props.item)"
                                    >Reject</v-btn>
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- pending -->
                                <v-list-item v-if="props.item.status === 'reject'">
                                  <v-list-item-icon>
                                    <v-icon>insert_drive_file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-btn
                                      color="#ffcc00"
                                      dark
                                      dense
                                      @click="pendingfile(props.item)"
                                    >Pending</v-btn>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  v-if="props.item.status === 'request' && props.item.trash === 'alive'"
                                >
                                  <div class="pr-3 pl-2">
                                    <v-btn color="amber" dark @click="btnunsend(props.item)">Unsend</v-btn>
                                  </div>
                                  <div>
                                    <v-btn
                                      class
                                      color="red"
                                      dark
                                      @click="btnnotapprove(props.item)"
                                    >No</v-btn>
                                  </div>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div class="text-xs-center">
                      <br />
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :color="color.theme"
                        :total-visible="10"
                        @input="clickpagination()"
                      ></v-pagination>
                    </div>
                  </div>
                  <!-- Table by Files -->
                  <div v-else>
                    <v-data-table
                      v-model="filemultipledownload"
                      :headers="headerscompany"
                      :items="inboxdata"
                      item-key="receiver_id"
                      :no-results-text="nodata"
                      :single-select="false"
                      :show-select="statusmutipledownload"
                      class="elevation-1"
                      :page.sync="page"
                      :items-per-page="size"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.system_type`]="{ header }">
                        <span :style="headerTable">{{ header.text }}</span>
                      </template>
                      <template v-slot:[`header.fullname_th`]="{ header }" v-if="$i18n.locale === 'th'">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:[`header.fullname_eng`]="{ header }" v-else>
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.sum_receive`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr style="cursor: pointer" @click="gotosourcesystem(props.item)">
                          <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox
                              v-model="filemultipledownload"
                              :value="props.item"
                              v-if="props.item.trash !=='DELETE'"
                            />
                          </td>
                          <td class="text-center">
                            <v-icon large :color="color.theme" style="font-weight: 100">business</v-icon>
                          </td>
                          <!-- <td v-if="$i18n.locale === 'th'">{{ props.item.fullname_th}}</td>
                          <td v-else>{{ props.item.fullname_eng}}</td>-->
                          <td>{{ $t('default') === 'th' ? props.item.receiver_name : props.item.receiver_name_eng}}</td>
                          <td class="text-center">
                            <v-chip
                              v-if="props.item.sum_receive >0"
                              class="ma-2"
                              color="red"
                              text-color="white"
                            >{{ props.item.sum_receive}}</v-chip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div class="text-xs-center">
                      <br />
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :color="color.theme"
                        :total-visible="10"
                      ></v-pagination>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <previewfile
            :show="openpreviewfile"
            :filedata="currentfile"
            @closepreviewfile="openpreviewfile = false"
            @openpreviewfile="openpreviewfile = true"
          ></previewfile>
          <dialogdetailinbox
            :show="opendialog"
            @closedialog="opendialog =false"
            :fileinbox="newdata"
          />
          <dialogdetail
            :show="opendialogtrue"
            @closedialog="opendialogtrue =false"
            :fileinbox=" newdata"
            :inboxdata="inboxdata"
          />
          <dialogrelation
            :show="openreletion"
            @closedialog="openreletion=false"
            :fileinbox="inboxdata"
          />
          <inboxdetail
            :show="openinboxdetail"
            :filedata="currentinboxfile"
            @closeinboxdetail="openinboxdetail = false"
            @openinboxdetail="openinboxdetail = true"
          ></inboxdetail>
          <inboxrightclick
            :parentfolder="parentfolder"
            :show="showinboxMenu"
            :AreaX="x"
            :AreaY="y"
            @closeinboxmenu="showinboxMenu= false"
            :file="currentinboxfile"
            @downloadfile="downloadfile(currentinboxfile)"
          ></inboxrightclick>
          <dialogcheckrequestdelete
            :show="opendialogrequestdelete"
            @closedialog="opendialogrequestdelete=false"
            @reload="getInboxDataV3()"
            :data="data"
            :status="statusrequestdelete"
          ></dialogcheckrequestdelete>
          <dialogcheckdelete
            :show="opendialogdelete"
            @closedialog="opendialogdelete=false"
            @reload="getInboxDataV3()"
            :data="data"
            :status="statusdelete"
          ></dialogcheckdelete>
          <dialogsnackbermultidowload
            :show="opensnackbarmultidownload"
            @closedialog="opensnackbarmultidownload=false"
            :percent="percent"
            :date='datetime'
            :namedowload="namedowload"
            :btsdownload="btsdownload"
            :count_loadingfile="count_loadingfile"
            :count_allfile="count_allfile"
            :statusloading="statusloading"
          ></dialogsnackbermultidowload>
          <dialogselectheader
            :show="opendialogselectheader"
            @closedialog="opendialogselectheader = false"
            @selectheaderbeforedownload="checkdownloadcsvandexcel"
          ></dialogselectheader>
          <dialogsnackbarloading_print
            :show="opensnackbarloadingprint"
            @closedialog="opensnackbarloadingprint = false"
            :percent="percent"
            :date="datetime"
          ></dialogsnackbarloading_print>
           <!-- snackbardowload show dowloadfilemulti error -->
          <div class="text-center">
              <v-snackbar :style="`padding-top: ${i * 90}px`" v-for="(s,i) in snackbars" :key="i" v-model="snackbar_download_error"  :timeout="20000" top right>
                 <v-list style="padding-top: 0px;">
                <v-list-item dense style="height:40px; width:450px;" three-line>
                  <v-list-item-icon>
                     <v-icon class="mt-6" large color="red">mdi-alert-octagon-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ s.filename }}</v-list-item-title>
                    <v-list-item-subtitle>{{"errorMessage : " +  s.error }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="mt-2" color="red" text @click="hide(i)" fab small >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              </v-snackbar>
          </div>
          <!-- snackbardowload -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowload" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowload = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title>{{InboxFilesByCompany}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
          <!-- snackbardowloadfile 1 file -->
          <!-- <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonefile" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonefile = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title>{{namedowload}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div> -->

          <!-- snackbardowloadfileทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotal" :timeout="timeout" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotal = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>         
                  <!-- <v-list-item-title style="color:black">{{ExportInboxFiles}}</v-list-item-title>   -->
                  <v-list-item-title style="color:black">{{ this.datenow + '_InboxFiles' }}</v-list-item-title>    
                  <v-list-item-subtitle class="pa-1">
                    <v-progress-linear
                      :value="percentCompleted"
                      color="teal"
                  ></v-progress-linear>
                  </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon v-if="percentCompleted === 100 " color="green" >mdi-check-bold</v-icon>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
         
          <!-- snackbardowloadfilecsv ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalcsv" :timeout="timeout" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalcsv = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                     <v-list-item-content>         
                  <v-list-item-title style="color:black">{{ExportInboxFilescsv}}</v-list-item-title>    
                  <v-list-item-subtitle class="pa-1">
                    <v-progress-linear
                      :value="percentCompleted"
                      color="teal"
                  ></v-progress-linear>
                  </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon v-if="percentCompleted === 100 " color="green" >mdi-check-bold</v-icon>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
          
          <!-- snackbardowloadfileexcel ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalexcel" :timeout="timeout" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalexcel = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                      <v-list-item-content>         
                  <v-list-item-title style="color:black">{{ExportInboxFilesexcel}}</v-list-item-title>    
                  <v-list-item-subtitle class="pa-1">
                    <v-progress-linear
                      :value="percentCompleted"
                      color="teal"
                  ></v-progress-linear>
                  </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon v-if="percentCompleted === 100 " color="green" >mdi-check-bold</v-icon>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
        </v-card-text>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import axios, { CancelToken } from 'axios';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
//   import onebox_toolbar from "@/components/layout/layout-toolbar-new";
import onebox_toolbar_inboxoutbox from "../components/layout/layout-inboxoutbox-toolbar";
import format from "date-fns/format";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const previewfile = () => import("@/components/optional/dialog-preview");
const inboxdetail = () => import("@/components/optional/dialog-detail");
const inboxrightclick = () =>
  import("../components/contextmenu/inboxrightclick");
const dialogdetailinbox = () =>
  import("../components/optional/dialog-detailinbox");
const dialogdetail = () => import("../components/optional/dialog-detail");
const dialogrelation = () =>
  import("../components/optional/dialog-insertrelation");
const dialogcheckdelete = () => import("../components/optional/dialog-checkdelete");
const dialogcheckrequestdelete = () => import("../components/optional/dialog-checkrequestdelete");
const dialogsnackbermultidowload =() => import("../components/optional/dialog-snackbarmultidowmload");
const dialogselectheader = () => import("../components/optional/dialog-selectheader.vue");
const dialogsnackbarloading_print = () => import("../components/optional/dialog-snackbarloadding-print.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  name: "allinbox_system",
  components: {
    onebox_toolbar_inboxoutbox,
    previewfile,
    inboxdetail,
    inboxrightclick,
    dialogdetailinbox,
    dialogdetail,
    dialogrelation,
    dialogcheckdelete,
    dialogcheckrequestdelete,
    dialogsnackbermultidowload,
    dialogselectheader,
    dialogsnackbarloading_print
  },
  data: function () {
    return {
      statusloading:"",
      openload:false,
      total_download:0,
      loading_company:0,
      count_loadingfile:0,
      count_allfile:0,
      closeOnContentClick:false,
      typedownloads:false,
      typeitem:false,
      opensnackbarloadingprint:false,
      loaddataprogress_print:false,
      show_menu:false,
      show_multipledownload:false,
      show_print:false,
      show_clear:false,
      show_download_all: false,
      show_download_all_csv: false,
      show_download_all_excel: false,
      sorting: {
        sort: [{
            id: 'document_date',
            text: 'sorting.document_date'
          }, {
            id: 'document_type',
            text: 'sorting.document_type'
          }, {
            id: 'cre_dtm',
            text: 'sorting.cre_dtm'
          }, {
            id: 'customer_name',
            text: 'sorting.customer_name'
          },{
            id: 'saler_name',
            text: 'sorting.saler_name'
          },{
             id: 'filename',
            text: 'sorting.filename'
          },{
            id: 'document_no',
            text: 'sorting.doc_no'
          },{
            id: 'status',
            text: 'sorting.status'
          }
        ],
        direction: [{
            id: 'ASC',
            text: 'sorting.ascending'
          }, {
            id: 'DESC',
            text: 'sorting.descending'
          }
        ]
      },
      sort_by: "filename",
      direction: "ASC",
      datenow: format(new Date(), "dd_MM_yyyy"),
      timeout: -1,
      snackbar_download_error:false,
      snackbars:[],
      filename_error:'',
      errorMessage:'',
      multistatus_etax:"",
      statusetax: [],
      typedownload:'',
      listheader:[],
      opendialogselectheader:false,
      maxDisplay:1,
      checkloaddata:false,
      percent:0,
      btsdownload:false,
      namedowload:'',
      opensnackbarmultidownload:false,
      statusrequestdelete:'',
      statusdelete:'',
      data:'',
      clickSortorderby: false,
      checkpagination: false,
      openreletion: false,
      snackbardowloadonetotal: false,
      snackbardowloadonetotalcsv: false,
      snackbardowloadonetotalexcel: false,
      InboxFile: "InboxFile",
      ExportInboxFiles: "ExportInboxFiles",
      ExportInboxFilescsv: "ExportInboxFilescsv",
      ExportInboxFilesexcel: "ExportInboxFilesexcel",
      newdata: [],
      opendialog: false,
      opendialogtrue: false,
      // liststatus: ["All", "Active", "Pending", "Reject", "Request", "Unsend"],
      liststatus: ["All","Active", "Pending","Duplicate","request for removal","resend from etax"],
      statusde: "All",
      sortStatus: "",
      snackbardowloadonefile: false,
      InboxFilesByCompany: "InboxFilesByCompany.zip",
      percentCompleted: 0,
      percent: 0,
      datetime: "",
      snackbardowload: false,
      datatype: { id: 1, type: "myinboxPage.typecompany" },
      listdatatype: [
        { id: 1, type: "myinboxPage.typecompany" },
        { id: 2, type: "myinboxPage.typefiles" },
      ],
      filemultipledownload: [],
      statusmutipledownload: false,
      defaulticon: "",
      listExtensionFile: [
        {
          data_type: "pdf",
        },
        {
          data_type: "xml",
        },
      ],
      listDoctype:[],
      // listDoctype: [
      //   {
      //     document_type: "ใบเพิ่มหนี้",
      //     document_type_code:"80"
         
      //   },
      //   {
      //     document_type: "ใบลดหนี้",
      //     document_type_code:"81"
      //   },
      //   {
      //     document_type: "ใบส่งของ",
      //     document_type_code:"207"
      //   },
      //   {
      //     document_type: "บัญชีสินค้าเดินพิธี",
      //     document_type_code:"325"
      //   },
      //   {
      //     document_type: "ใบแจ้งหนี้",
      //     document_type_code:"380"
      //   },
      //      {
      //     document_type: "ใบลดหนี้การค้า",
      //     document_type_code:"381"
      //   },
 
      //    {
      //     document_type: "ใบเพิ่มหนี้การค้า",
      //     document_type_code:"383"
      //   },
      //   {
      //     document_type: "ใบกำกับภาษี",
      //     document_type_code:"388"
      //   },
      //   {
      //     document_type: "ใบเสร็จค่า Freight",
      //     document_type_code:"623"
      //   },
      //   {
      //     document_type: "ใบรับ (ใบเสร็จรับเงิน)",
      //     document_type_code:"T01"
      //   },
      //   {
      //     document_type: "ใบแจ้งหนี้/ใบกำกับภาษี",
      //     document_type_code:"T02"
      //   },
      //   {
      //     document_type: "ใบเสร็จรับเงิน/ใบกำกับภาษี ",
      //     document_type_code:"T03"
      //   },
      //   {
      //     document_type: "ใบส่งของ/ใบกำกับภาษี",
      //     document_type_code:"T04"
      //   },
      //   {
      //     document_type: "ใบกำกับภาษีอย่างย่อ",
      //     document_type_code:"T05"
      //   },
      //   {
      //     document_type: "ใบยกเลิก",
      //     document_type_code:"T07"
      //   },
      // ],
      currentfile: {},
      currentinboxfile: {},
      openpreviewfile: false,
      openinboxdetail: false,
      opendialogdelete:false,
      opendialogrequestdelete:false,
      showinboxMenu: false,
      x: 0,
      y: 0,
      nodata: this.$t("myinboxPage.Nodata"),
      panel: [0],
      filename: "",
      fileextension: [],
      documentid: "",
      documentidto: "",
      documenttype: [],
      systemname: "",
      sendername: "",
      sellertaxid: "",
      sellerbranchid: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      loaddataprogress: false,
      inboxdata: [],
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "data_type",
          width: "5%",
        },
        {
          text: "tablefile.filenameonly",
          value: "filename",
          align: "left",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.docno",
          align: "left",
          value: "document_no",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.docdate",
          align: "center",
          value: "document_date",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.doctype",
          value: "document_type",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.sendername_",
          value: "sender_name",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.receivername_",
          value: "receiver_name",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.inboxdate",
          value: "cre_dtm",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: "สถานะขอคืนภาษี",
          value: "tax_refund",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.option",
          value: "file_id",
          align: "left",
          sortable: false,
          width: "5%",
        },
      ],
      headerscompanyth: [
        {
          text: "",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "allbusinessPage.tablebusinesslist.business",
          align: "left",
          value: "fullname_th",
          width: "83%",
          sortable: false,
        },
        {
          text: "allbusinessPage.tablebusinesslist.record",
          align: "center",
          value: "sum_receive",
          width: "10%",
          sortable: false,
        },
      ],
      headerscompanyeng: [
        {
          text: "",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "allbusinessPage.tablebusinesslist.business",
          align: "left",
          value: "fullname_eng",
          width: "83%",
          sortable: false,
        },
        {
          text: "allbusinessPage.tablebusinesslist.record",
          align: "center",
          value: "sum_receive",
          width: "10%",
          sortable: false,
        },
      ],
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      size: 10,
      page: 1,
      totalfile: 0,
      sortby: "",
      clickSort: false,
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      multiSort: false,
      liststatusetax: [
        {
          statusetax_type: "ขอคืนภาษีแล้ว",
          statusetax_value: "Y",
        },
        {
          statusetax_type: "ยังไม่ขอคืนภาษี",
          statusetax_value: "N",
        },
      ],
      // package: "",
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",      
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = 0;
        if (this.datatype["id"] === 1) {
          l = this.inboxdata.length;
        } else {
          l = this.totalfile;
        }

        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata: {
      get() {
        return this.$route.query.sourcesystem || "-";
      },
    },
    headerscompany() {
      if (this.$t("default") === "th") {
        return this.headerscompanyth;
      } else {
        return this.headerscompanyeng;
      }
    },
    paginatedreceiverData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.inboxdata.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    icon () {
        if (this.Alldoctype) return 'mdi-close-box'
        if (this.Somedoctype) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    },
    icons(){
      if (this.Allfileextension) return 'mdi-close-box'
      if (this.Somefileextension) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    icon_() {
      if (this.Allstatusetax) return "mdi-close-box"
      if (this.Somestatusetax) return "mdi-minus-box"
      return 'mdi-checkbox-blank-outline'
    },
    Alldoctype(){
        return this.documenttype.length === this.listDoctype.length
    },
    Somedoctype(){
        return this.documenttype.length > 0 && !this.Alldoctype
    },
    Allfileextension(){
      return this.fileextension.length === this.listExtensionFile.length
    },
    Somefileextension(){
      return this.fileextension.length > 0 && !this.Allfileextension
    },
    Allstatusetax() {
      console.log("-",this.statusetax.length === this.liststatusetax.length)
      return this.statusetax.length === this.liststatusetax.length
    },
    Somestatusetax() {
      console.log("-=-",this.statusetax.length > 0 && !this.Allstatusetax);
      return this.statusetax.length > 0 && !this.Allstatusetax
    },
    disableByPackage() {      
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      // return this.inboxdata.filter(data => data["package"] === "Y").length === 0
      return false
    },
    listsize() {
      return [
        { value: 10, disable: false }, 
        { value: 20, disable: this.disableByPackage }, 
        { value: 50, disable: this.disableByPackage }, 
        { value: 100, disable: this.disableByPackage }, 
        { value: 1000, disable: this.disableByPackage },     
      ]
    },
  },
  filters: {
    subStr: function (string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  watch: {
    sort_by() {
      this.searchdata();
      // this.getSourceSystem();
      // this.getDataFilters();
    },
    direction() {
     this.searchdata();
      // this.getSourceSystem();
      // this.getDataFilters();
    }
  },
  methods: {
    Clear_data(){
      this.filename = '',
      this.fileextension = [],
      this.fromdateupload = '',
      this.todateupload = '',
      this.sendername = '',
      this.receivername = '',
      this.documenttype = [],
      this.statusetax = [],
      this.documentid = '',
      this.documentidto = '',
      this.fromdatedocument = '',
      this.todatedocument = '',
      this.sellertaxid = '',
      this.sellerbranchid = '',
      this.buyertaxid = '',
      this.buyerbranchid = ''
    },
    async fn_printPDF() {
      this.opensnackbarloadingprint = true;
      // this.loaddataprogress_print = true;
      this.percent = 0;
      let listfile = "";
      let notpdf = false;
      let i;
      console.log("this.filemultipledownload",this.filemultipledownload);
      for (i = 0; i < this.filemultipledownload.length; i++) {
        if (this.filemultipledownload[i]["data_type"] !== "pdf") {
          Toast.fire({
            icon: "error",
            title: "โปรดเลือกเฉพาะไฟล์ PDF",
          });
          notpdf = true;
          this.opensnackbarloadingprint = false;
          // this.loaddataprogress_print = false;
        // } else if (this.filemultipledownload[i]["status_lock_data"] === "Y") {
        //   Toast.fire({
        //     icon: "error",
        //     title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
        //   });
        //   notpdf = true;
        //   this.loaddataprogress_print = false;
        } else {
          listfile += "&list_file=" + this.filemultipledownload[i]["file_id"];
          //console.log(listfile);
        }
      }
      if (i === this.filemultipledownload.length && notpdf !== true) {
        let auth = await gbfGenerate.generateToken();

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/merge_pdf?account_id=" +
          this.dataAccountId +
          "&user_id=" +
          this.dataUsername +
          "&Authorization=" +
          auth.code +
          listfile;
        this.axios.get(url,{
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                console.log("progressEvent", progressEvent);
                let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // this.loadingpercent = progresspercent;
                this.percent = progresspercent;
                // console.log("progresspercent",progresspercent);
              }
            }).then((response) => {
          console.log(response.data.status);
          if (response.data.status !== "ER") {
            this.statusmutipledownload = false;
            this.filemultipledownload = [];
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
            window.open(url);
          } else {
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถรวมไฟล์เสียได้",
            });
          }
        });
      }
    },
    async fn_changestatusetax_multi(_item, status) {
      if(this.filemultipledownload.length > 0){  
      let listfilemulti = []
      for(let i = 0 ; i< this.filemultipledownload.length; i++){
        listfilemulti.push(this.filemultipledownload[i].inbox_id)
      }
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        inbox_id: listfilemulti,
        user_id: this.dataUsername,
        refund_status: status,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/update_inbox_tax_refund", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.multistatus_etax = "";
            Toast.fire({
              icon: "success",
              title: "Update Status Success",
            });
            this.getInboxDataV3();
          }
        });
      }else{
        this.multistatus_etax = "";
        Toast.fire({
          type: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }
    },
    async fn_changestatusetax(_item, status) {
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        inbox_id: [_item.inbox_id],
        user_id: this.dataUsername,
        refund_status: status,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/update_inbox_tax_refund", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Update Status Success",
            });
            this.getInboxDataV3();
          }
        });
    },
    fn_selectall_statusetax(){
    this.$nextTick(() => {
        if (this.Allstatusetax) {
          this.statusetax = []
          console.log("statusetax",this.statusetax);
        } else {
          this.statusetax = this.liststatusetax.slice()
          console.log("this.statusetax",this.statusetax);
        }
      })
    },
    fn_selectall_fileextension(){
      let listex = []
        this.$nextTick(() => {
          if (this.Allfileextension) {
            this.fileextension = []
            console.log("fileextension",this.fileextension);
          } else {
            console.log(this.fileextension);
            console.log(this.listExtensionFile);
            for(let i = 0; i < this.listExtensionFile.slice().length; i++ ){
              listex.push(this.listExtensionFile[i].data_type)
            }
            this.fileextension = listex
          }
        })
    },
    fn_selectall(){
      let listdocument = []
        this.$nextTick(() => {
          console.log(this.Alldoctype);
          if (this.Alldoctype) {
            this.documenttype = []
            console.log("documenttype",this.documenttype);
          } else {
            for(let i = 0; i < this.listDoctype.slice().length; i++ ){
              listdocument.push(this.listDoctype[i].document_type_code)
            }
            this.documenttype = listdocument
            console.log("documenttype else",this.documenttype);
          }
        })
      },
   async fn_deletetotrash(file) {  
        let payload;
        let filedata = [];
        console.log("file",file);
        filedata.push(file["inbox_id"]);
        payload = {
          inbox_id: filedata,
          account_id: this.dataAccountId,
        };
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_inbox",
            payload,{
            headers: { Authorization: auth.code },}
          )
          .then((response) => {
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "OK",
              });
              console.log("delete", response.length, response.data);
              // this.clearmultipledownload();
              this.statusmutipledownload = false;
              this.getInboxDataV3();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
    },
    // deleteinbox(item_ , status){
    //   console.log(item_);
    //   console.log(status);
    //   let payload = {
    //     account_id:this.dataAccountId,
    //     inbox_id:[item_.inbox_id],
    //     send_request: status,
    //     delete_log:"inbox",
    //     user_id:this.dataUsername
    //   }
    //   console.log("payload",payload);
    //     this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox",
    //       payload
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status === "OK") {
    //         if(status === 'N'){
    //            Toast.fire({
    //           icon: "success",
    //           title: "Delete Success",
    //         });
    //         this.getInboxDataV3();
    //         }else if(status === 'Y'){
    //             Toast.fire({
    //           icon: "success",
    //           title: "Delete & Request Success",
    //         });
    //         this.getInboxDataV3();
    //         }           
    //       }
    //     });
    // },
 async deleterequest(_item ,status){
      console.log(_item,status);
      let payload = {
        account_id:this.dataAccountId,
        inbox_id:[_item.inbox_id],
        request_answer:status,
        delete_log:"inbox",
        user_id:this.dataUsername
      }
      let auth = await gbfGenerate.generateToken();
      console.log("payload",payload);
       await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox_requset",
          payload,{ headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if(status === 'Y'){
               Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            this.getInboxDataV3();
            }else if(status === 'N'){
                Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getInboxDataV3();
            }           
          }
        });
    },
   async btnnotapprove(item) {
      let payload = {
        inbox_id: [item.inbox_id],
        status: "not_approved",
        user_id: "",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("payload", payload);
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_unsend_inbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getInboxDataV3();
          }
        });
    },
   async btnunsend(item) {
      let payload = {
        inbox_id: [item.inbox_id],
        status: "unsend",
        user_id: "",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("payload", payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_unsend_inbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getInboxDataV3();
          }
        });
    },
    closeclicksort() {
      this.clickSortorderby = false;
      this.checkpagination = false;
      this.check();
    },
    check() {
      if (this.checkpagination === true) {
        this.checksortorderby();
      } else {
        this.searchdata();
      }
    },
    checksortorderby() {
      this.inboxdata = [];
      this.buff = [];
      let business = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        business.push(this.dataBusinessProfile[i]["business_id"]);
      }
      this.sortorderby(business, "2");
    },
   async sortorderby(_accountid, _type) {
      this.checkloaddata = true;
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      let payload = {
        limit: this.size.toString(),
        offset: offset.toString(),
        receiver_id: _accountid,
        receiver_type: _type,
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v3/get_file_inbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((res) => {
          if (res.data.status === "OK") {
            // this.inboxdata = [];
            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              //   for(let i=0;i < res.data.result.data.length ; i++){
              //   if(res.data.result.data[i].relation_status === 'True'){
              //     console.log(res.data.result.data[i]);
              //     this.inboxdata.push(res.data.result.data[i]);
              //     console.log(this.inboxdata);
              //   }
              // }
              this.inboxdata = res.data.result["data"];
              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.inboxdata = [];
              this.buff = [];
            }
            this.checkloaddata = false;
            this.loaddataprogress = false;
          } else {
            this.checkloaddata = false;
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch((error) => {
          this.checkloaddata = false;
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
   async pendingfile(file) {
      let payload;
      payload = {
        inbox_id: [file.inbox_id],
        status: "pending",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/set_pending_active_inbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          if ((response.data.status = "OK")) {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.getInboxDataV3();
          } else {
          }
        });
    },
   async rejectfile(file) {
      let payload;
      payload = {
        inbox_id: [file.inbox_id],
        status: "reject",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/set_pending_active_inbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          if ((response.data.status = "OK")) {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.getInboxDataV3();
          } else {
          }
        });
    },
   async approvefile(file) {
      let payload;
      payload = {
        inbox_id: [file.inbox_id],
        status: "active",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/set_pending_active_inbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          if ((response.data.status = "OK")) {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.getInboxDataV3();
          } else {
          }
        });
    },
    clickstatus(val) {
      let sortStatus = "";
      this.page = 1;
      if (val === "All") {
        this.sortStatus = "";
      } else if (val === "Active") {
        this.sortStatus = "active";
      } else if (val === "Pending") {
        this.sortStatus = "pending";
      } else if (val === "Reject") {
        this.sortStatus = "reject";
      } else if (val === "Request") {
        this.sortStatus = "request";
      } else if (val === "Unsend") {
        this.sortStatus = "unsend";
      }else if(val === "Duplicate"){
        this.sortStatus = 'duplicate'
      }else if(val === "request for removal"){
        this.sortStatus = 'request_for_removal'
      }else if(val === "resend from etax") {
        this.sortStatus = "resend";
      }
      console.log(this.sortStatus);

      this.getInboxDataV3();
    },
    show(e) {
      e.preventDefault();
      this.sendParentfolder();
      //this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          // this.showMenu = false;
        } else {
          setTimeout(() => {
            //  this.showMenu = true;
          }, 300);
        }
      });
    },
    rightclickinboxfile(e, filename) {
      // console.log(e);
      e.preventDefault();
      // this.sendParentfolder();
      this.showinboxMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      //this.showMenu = false;
      this.$nextTick(() => {
        this.currentinboxfile = filename;
        this.showinboxMenu = true;
        setTimeout(() => {
          this.showinboxMenu = true;
        }, 300);
      });
    },
    checkSort() {
      // this.loadfolder();
      this.inboxdata = this.buff;
      this.clickSort = false;
      this.multiSort = false;
      // this.multiSortdate=false
      // this.multiSortsize=false
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.inboxdata = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].data_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }
      if (this.sortby === "name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.filename.localeCompare(b.filename);
          });
          this.fileSort.sort(function (a, b) {
            return a.filename.localeCompare(b.filename);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.filename.localeCompare(a.filename);
          });
          this.fileSort.sort(function (a, b) {
            return b.filename.localeCompare(a.filename);
          });
        }
      } else if (this.sortby === "inboxdate") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.cre_dtm.localeCompare(b.cre_dtm);
          });
          this.fileSort.sort(function (a, b) {
            return a.cre_dtm.localeCompare(b.cre_dtm);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.cre_dtm.localeCompare(a.cre_dtm);
          });
          this.fileSort.sort(function (a, b) {
            return b.cre_dtm.localeCompare(a.cre_dtm);
          });
        }
      } else if (this.sortby === "docdate") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.document_date.localeCompare(b.document_date);
          });
          this.fileSort.sort(function (a, b) {
            return a.document_date.localeCompare(b.document_date);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.document_date.localeCompare(a.document_date);
          });
          this.fileSort.sort(function (a, b) {
            return b.document_date.localeCompare(a.document_date);
          });
        }
      } else if (this.sortby === "docno") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.document_no.localeCompare(b.document_no);
          });
          this.fileSort.sort(function (a, b) {
            return a.document_no.localeCompare(b.document_no);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.document_no.localeCompare(a.document_no);
          });
          this.fileSort.sort(function (a, b) {
            return b.document_no.localeCompare(a.document_no);
          });
        }
      } else if (this.sortby === "doctype") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.document_type.localeCompare(b.document_type);
          });
          this.fileSort.sort(function (a, b) {
            return a.document_type.localeCompare(b.document_type);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.document_type.localeCompare(a.document_type);
          });
          this.fileSort.sort(function (a, b) {
            return b.document_type.localeCompare(a.document_type);
          });
        }
      } else if (this.sortby === "sendname") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.sender_name.localeCompare(b.sender_name);
          });
          this.fileSort.sort(function (a, b) {
            return a.sender_name.localeCompare(b.sender_name);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.sender_name.localeCompare(a.sender_name);
          });
          this.fileSort.sort(function (a, b) {
            return b.sender_name.localeCompare(a.sender_name);
          });
        }
      } else if (this.sortby === "receivername") {
        if (this.multiSort === true) {
          this.folderSort.sort(function (a, b) {
            return a.receiver_name.localeCompare(b.receiver_name);
          });
          this.fileSort.sort(function (a, b) {
            return a.receiver_name.localeCompare(b.receiver_name);
          });
        } else {
          this.folderSort.sort(function (a, b) {
            return b.receiver_name.localeCompare(a.receiver_name);
          });
          this.fileSort.sort(function (a, b) {
            return b.receiver_name.localeCompare(a.receiver_name);
          });
        }
      }

      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.inboxdata.push(this.sortdata[i][j]);
        }
      }
      return this.inboxdata;
    },
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       type: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.dataUsername +
    //       "&file_id=" +
    //       currentfile.file_id;
    //     window.location.assign(url);
    //   }
    // },
    // fn_calculate_size(_sizefilebyte) {
    //   console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
    //    return parseInt((_sizefilebyte / 1073741824).toFixed(2))
    //   // return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    // },
    downloadfile(currentfile) {
      console.log(currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size)
      this.percentCompleted = 0;
      this.percent = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          icon: "warning",
          title: "Coming Soon !!",
        });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // this.snackbardowloadonefile = true;
        // if(checkfilesize < 1.00){
        this.btsdownload = true;
        this.opensnackbarmultidownload = true;
        this.namedowload = currentfile.filename;
        this.axios
          .get(url, {
            onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // console.log(progresspercent);
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            if (response.statusText === "OK") {
              if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percentCompleted = 100;
                this.btsdownload = false;

                let headers = response.headers;
                let blob = new Blob([response.data],{type:headers['content-type']});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = currentfile.filename;
                link.click();

                setTimeout(() => {
                  this.opensnackbarmultidownload = false
                  }, 2500);
              }
            }else{
              this.opensnackbarmultidownload = false
            }
            // const blob = new Blob([response.data]);
            // const content = response.headers["content-type"];
            // saveAs(blob, currentfile.filename);
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = true;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้",
            });
            console.log(error);
          });
        // }else{
        //   window.location.assign(url)
        //   this.btsdownload = false;
        //   this.opensnackbarmultidownload = false;
        // }
      }
    },
    previewfile(file) {
      // console.log(file);
      this.currentfile = file;
      this.openpreviewfile = true;
    },
    inboxdetail(file) {
      this.openinboxdetail = true;
      this.currentinboxfile = file;
    },
    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
    },
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      this.todatedocument = format(new Date(), "yyyy-MM-dd");
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    seticon_new(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link","pink"]
    }
      else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    setformatdate(_date) {
      if (_date === "" || _date === null || _date === undefined) {
        return "-";
      } else {
        let data =
          _date.substring(8, 6) +
          "/" +
          _date.substring(6, 4) +
          "/" +
          _date.substring(0, 4);
        return data || "-";
      }
    },

    // 23/02/2020
    getDataFilters() {
      this.getDataDocType(this.dataAccountId, "1");
      // this.getDataExtensionFile(this.dataAccountId, "1");
      // if (this.dataAccountActive["type"] === "Business") {
      //   this.loadSourceSystem(_accountid, _type)
      // } else {
      //   this.loadSourceSystem(_accountid, _type)
      // }
    },
   async getDataDocType(_accountid, _type) {
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_all_doctype",
          payload,{ headers: { Authorization: auth.code },},
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.listDoctype = res.data.result;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
   async getDataExtensionFile(_accountid, _type) {
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX +
            "/api/get_all_fileextension",
          payload,{ headers: { Authorization: auth.code },},
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.listExtensionFile = res.data.result;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportfile() {
      this.callexportfile();
    },
    async callexportfile() {
      this.percentCompleted = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      // this.$store.dispatch("show_loading", true);
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }
      let payload = {
        receiver_id: receiver,
        receiver_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        account_id: this.dataAccountId,
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N'
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
          // onDownloadProgress: (progressEvent) => {
          //     let progresspercent = Math.round(
          //       (progressEvent.loaded * 100) / progressEvent.total
          //     );
          //     // console.log(progresspercent);
          //     if (progresspercent === 95) {
          //       this.percentCompleted = 95;
          //     } else {
          //       this.percentCompleted = progresspercent;
          //     }
          //   },
          //   withCredentials: false,
          //   responseType: "arraybuffer",
        }
      await this.axios
        // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/v2/downloads_file_byfilter", payload, config)
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/prepare_zip_file_inbox_by_filter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);     
            this.fn_loop_multidownloading(response.data.all_file, response.data.zip_name, response.data.url);
            this.count_allfile = response.data.total;
          // if (response.statusText === "OK") {
          //   if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
          //       console.log("response < 1000", response);
          //       this.snackbardowloadonetotal = false;
          //       let res_error = gbfdecodearraybuffer.decodearraybuffer(
          //         response.data
          //       );
          //       console.log("responseerror", res_error);
          //       Toast.fire({
          //         icon: "error",
          //         title: res_error.errorMessage,
          //       });
          //     } else {
          //       this.percentCompleted = 100;
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, this.ExportInboxFiles + ".zip");
          //       let headers = response.headers;
          //       let blob = new Blob([response.data],{type:headers['content-type']});
          //       let link = document.createElement('a');
          //       link.href = window.URL.createObjectURL(blob);
          //       link.download = this.ExportInboxFiles;
          //       link.click();
          //       setTimeout(() => {
          //         this.snackbardowloadonetotal = false;
          //       }, 2500);
          //     }
          // }
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังดาวน์โหลดไฟล์";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.InboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          
          if(res.status !== "OK" ){
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            console.log("เข้า");
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_inbox_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
           if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) === "application/json"
                ) {
                  console.log("response < 1000", response);
                  this.opensnackbarmultidownload = false;
                  this.snackbardowloadonetotal = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percent= 100;
                  this.percentCompleted = 100;
                  this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  // link.download = this.InboxFile + "_" + this.datetime;
                  link.download = format(new Date(), "dd_MM_yyyy") + '_InboxFiles';
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                    this.snackbardowloadonetotal = false;
                  }, 2500);
                }
              }
              // this.snackbars=[];
              this.typedownloads = false;
              this.typeitem = false;
        });
      });
    },


    exportfilecsv() {
      // console.log(this.inboxdata);
      this.callexportfilecsv();
    },
    // async callexportfilecsv_new() {
    //  this.percentCompleted = 0;
    //   // this.$store.dispatch("show_loading", true);
    //   let receiver = [];
    //   for (let i = 0; i < this.dataBusinessProfile.length; i++) {
    //     receiver.push(this.dataBusinessProfile[i]["business_id"]);
    //   }
    //   console.log(receiver);

    //   let payload = {
    //     receiver_id: receiver,
    //     receiver_type: "2",
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus,
    //     download:"csv",
    //     index:this.listheader
    //   };

    //   console.log("payloadallbusiness", payload);
    //   this.snackbardowloadonetotalcsv = true;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //       onDownloadProgress: (progressEvent) => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer",
    //     }
    //  await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //         "/api/download_excel_file_inbox_byfilter_costom_index",
    //       payload,config
    //     )
    //     .then((response) => {
    //       if (response.statusText === "OK") {
    //         if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             console.log("response < 1000", response);
    //             this.snackbardowloadonetotalcsv = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //               response.data
    //             );
    //             console.log("responseerror", res_error);
    //             Toast.fire({
    //               icon: "error",
    //               title: res_error.errorMessage,
    //             });
    //           } else {
    //             this.percentCompleted = 100;
    //             // const blob = new Blob([response.data]);
    //             // const content = response.headers["content-type"];
    //             // saveAs(blob, this.ExportInboxFilescsv + ".zip");
    //             let headers = response.headers;
    //             let blob = new Blob([response.data],{type:headers['content-type']});
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = this.ExportInboxFilescsv;
    //             link.click();
    //             setTimeout(() => {
    //               this.snackbardowloadonetotalcsv = false;
    //             }, 2500);
    //           }
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilescsv + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },
  //  async callexportfilecsv() {
  //    this.percentCompleted = 0;
  //     // this.$store.dispatch("show_loading", true);
  //     let receiver = [];
  //     for (let i = 0; i < this.dataBusinessProfile.length; i++) {
  //       receiver.push(this.dataBusinessProfile[i]["business_id"]);
  //     }
  //     console.log(receiver);

  //     let payload = {
  //       account_id: this.dataAccountId,
  //       receiver_id: receiver,
  //       receiver_type: "2",
  //       filename: this.filename,
  //       fileextension: this.fileextension,
  //       doc_no_start: this.documentid,
  //       doc_no_end: this.documentidto,
  //       doc_date_start: this.formatDate(this.fromdatedocument),
  //       doc_date_end: this.formatDate(this.todatedocument),
  //       doc_type: this.documenttype,
  //       cre_dtm_start: this.formatDate(this.fromdateupload),
  //       cre_dtm_end: this.formatDate(this.todateupload),
  //       sender_name: this.sendername,
  //       system: this.$route.query.systemid,
  //       sellerTaxid: this.sellertaxid,
  //       sellerBranchid: this.sellerbranchid,
  //       receiver_name: this.receivername,
  //       receiverTaxid: this.buyertaxid,
  //       receiverBranchid: this.buyerbranchid,
  //       status:this.sortStatus
  //     };

  //     console.log("payloadallbusiness", payload);
  //     this.snackbardowloadonetotalcsv = true;
  //     this.timeout = -1;
  //     let auth = await gbfGenerate.generateToken();
  //     let config = {
  //       headers: { Authorization: auth.code},
  //         onDownloadProgress: (progressEvent) => {
  //             let progresspercent = Math.round(
  //               (progressEvent.loaded * 100) / progressEvent.total
  //             );
  //             // console.log(progresspercent);
  //             if (progresspercent === 95) {
  //               this.percentCompleted = 95;
  //             } else {
  //               this.percentCompleted = progresspercent;
  //             }
  //           },
  //           withCredentials: false,
  //           responseType: "arraybuffer",
  //       }
  //    await this.axios
  //       .post(
  //         process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
  //           "/api/download_csv_file_inbox_byfilter",
  //         payload,config
  //       )
  //       .then((response) => {
  //         if (response.statusText === "OK") {
  //           if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
  //               console.log("response < 1000", response);
  //               this.snackbardowloadonetotalcsv = false;
  //               let res_error = gbfdecodearraybuffer.decodearraybuffer(
  //                 response.data
  //               );
  //               console.log("responseerror", res_error);
  //               Toast.fire({
  //                 icon: "error",
  //                 title: res_error.errorMessage,
  //               });
  //             } else {
  //               this.percentCompleted = 100;
  //               // const blob = new Blob([response.data]);
  //               // const content = response.headers["content-type"];
  //               // saveAs(blob, this.ExportInboxFilescsv + ".zip");
  //               let headers = response.headers;
  //               let blob = new Blob([response.data],{type:headers['content-type']});
  //               let link = document.createElement('a');
  //               link.href = window.URL.createObjectURL(blob);
  //               link.download = this.ExportInboxFilescsv;
  //               link.click();
  //               setTimeout(() => {
  //                 this.snackbardowloadonetotalcsv = false;
  //               }, 2500);
  //             }
  //         }
  //       })
  //       .catch((error) => {
  //         Toast.fire({
  //           icon: "error",
  //           title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilescsv + " นี้ได้",
  //         });
  //         console.log(error);
  //       });
  //   },
    async callexportfilecsv() {
      this.percentCompleted = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }
      // receiver = this.inboxdata.filter(data => data["package"] === "Y").map(data => data["receiver_id"])
      let payload = {
        receiver_id: receiver,
        receiver_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N',
        sort_by: this.sort_by,
        direction: this.direction,
        refund_status: this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",

      };
      // this.snackbardowloadonetotal = true;
       this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
       
        }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_csv_file_inbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading_csv(response.data.all_file, response.data.zip_name, response.data.url);  
            this.count_allfile = response.data.total;
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_csv(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ CSV";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_csv(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.InboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_csv(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_csv(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_csv(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_inbox_csv_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
           if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) === "application/json"
                ) {
                  console.log("response < 1000", response);
                  this.opensnackbarmultidownload = false;
                  // this.snackbardowloadonetotal = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percent= 100;
                  this.percentCompleted = 100;
                  this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  // link.download = this.OutboxFile + "_" + this.datetime;
                  link.download = format(new Date(), "dd_MM_yyyy") + '_Inbox_CSV';
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                    // this.snackbardowloadonetotal = false;
                  }, 2500);
                }
              }
              this.typedownloads = false;
              this.typeitem = false;

        });
      });
    },


    exportfileexcel() {
      this.callexportfileexcel();
    },
    async callexportfileexcel() {
      this.percentCompleted = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }
      // receiver = this.inboxdata.filter(data => data["package"] === "Y").map(data => data["receiver_id"])
      // console.log("receiver", receiver)
      let payload = {
        account_id: this.dataAccountId,
        receiver_id: receiver,
        receiver_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N',
        sort_by: this.sort_by,
        direction: this.direction,
        refund_status: this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",

      };
      // this.snackbardowloadonetotal = true;
       this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
       
        }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_excel_file_inbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading_excel(response.data.all_file, response.data.zip_name, response.data.url);  
            this.count_allfile = response.data.total;
          } else {
            // this.snackbardowloadonetotal = false;
             this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
           this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_excel(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ Excel";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_excel(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_excel(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_excel(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_excel(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_excel_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
           if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) === "application/json"
                ) {
                  console.log("response < 1000", response);
                  this.opensnackbarmultidownload = false;
                  this.snackbardowloadonetotal = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percent= 100;
                  this.percentCompleted = 100;
                  this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  // link.download = this.OutboxFile + "_" + this.datetime;
                  link.download = format(new Date(), "dd_MM_yyyy") + '_Inbox_Excel';
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                    this.snackbardowloadonetotal = false;
                  }, 2500);
                }
              }
            this.typedownloads = false;
            this.typeitem = false;
        });
      });
    },


    // async callexportfileexcel_new() {
    //  this.percentCompleted = 0;
    //   // this.$store.dispatch("show_loading", true);
    //   let receiver = [];
    //   for (let i = 0; i < this.dataBusinessProfile.length; i++) {
    //     receiver.push(this.dataBusinessProfile[i]["business_id"]);
    //   }
    //   console.log(receiver);

    //   let payload = {
    //     receiver_id: receiver,
    //     receiver_type: "2",
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus,
    //     download:"excel",
    //     index:this.listheader
    //   };

    //   console.log("payloadallbusiness", payload);
    //   this.snackbardowloadonetotalexcel = true;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //       onDownloadProgress: (progressEvent) => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer",
    //     }
    // await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //         "/api/download_excel_file_inbox_byfilter_costom_index",
    //       payload,config
    //     )
    //     .then((response) => {
    //       if (response.statusText === "OK") {
    //         if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             console.log("response < 1000", response);
    //             this.snackbardowloadonetotalexcel = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //               response.data
    //             );
    //             console.log("responseerror", res_error);
    //             Toast.fire({
    //               icon: "error",
    //               title: res_error.errorMessage,
    //             });
    //           } else {
    //             this.percentCompleted = 100;
    //             // const blob = new Blob([response.data]);
    //             // const content = response.headers["content-type"];
    //             // saveAs(blob, this.ExportInboxFilesexcel + ".zip");
    //             let headers = response.headers;
    //             let blob = new Blob([response.data],{type:headers['content-type']});
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = this.ExportInboxFilesexcel;
    //             link.click();
    //             setTimeout(() => {
    //               this.snackbardowloadonetotalexcel = false;
    //             }, 2500);
    //           }
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilesexcel + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },
  //  async callexportfileexcel() {
  //    this.percentCompleted = 0;
  //     // this.$store.dispatch("show_loading", true);
  //     let receiver = [];
  //     for (let i = 0; i < this.dataBusinessProfile.length; i++) {
  //       receiver.push(this.dataBusinessProfile[i]["business_id"]);
  //     }
  //     console.log(receiver);

  //     let payload = {
  //       account_id: this.dataAccountId,
  //       receiver_id: receiver,
  //       receiver_type: "2",
  //       filename: this.filename,
  //       fileextension: this.fileextension,
  //       doc_no_start: this.documentid,
  //       doc_no_end: this.documentidto,
  //       doc_date_start: this.formatDate(this.fromdatedocument),
  //       doc_date_end: this.formatDate(this.todatedocument),
  //       doc_type: this.documenttype,
  //       cre_dtm_start: this.formatDate(this.fromdateupload),
  //       cre_dtm_end: this.formatDate(this.todateupload),
  //       sender_name: this.sendername,
  //       system: this.$route.query.systemid,
  //       sellerTaxid: this.sellertaxid,
  //       sellerBranchid: this.sellerbranchid,
  //       receiver_name: this.receivername,
  //       receiverTaxid: this.buyertaxid,
  //       receiverBranchid: this.buyerbranchid,
  //       status:this.sortStatus
  //     };

  //     console.log("payloadallbusiness", payload);
  //     this.snackbardowloadonetotalexcel = true;
  //     this.timeout = -1;
  //     let auth = await gbfGenerate.generateToken();
  //     let config = {
  //       headers: { Authorization: auth.code},
  //         onDownloadProgress: (progressEvent) => {
  //             let progresspercent = Math.round(
  //               (progressEvent.loaded * 100) / progressEvent.total
  //             );
  //             // console.log(progresspercent);
  //             if (progresspercent === 95) {
  //               this.percentCompleted = 95;
  //             } else {
  //               this.percentCompleted = progresspercent;
  //             }
  //           },
  //           withCredentials: false,
  //           responseType: "arraybuffer",
  //       }
  //   await this.axios
  //       .post(
  //         process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
  //           "/api/download_excel_file_inbox_byfilter",
  //         payload,config
  //       )
  //       .then((response) => {
  //         if (response.statusText === "OK") {
  //           if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
  //               console.log("response < 1000", response);
  //               this.snackbardowloadonetotalexcel = false;
  //               let res_error = gbfdecodearraybuffer.decodearraybuffer(
  //                 response.data
  //               );
  //               console.log("responseerror", res_error);
  //               Toast.fire({
  //                 icon: "error",
  //                 title: res_error.errorMessage,
  //               });
  //             } else {
  //               this.percentCompleted = 100;
  //               // const blob = new Blob([response.data]);
  //               // const content = response.headers["content-type"];
  //               // saveAs(blob, this.ExportInboxFilesexcel + ".zip");
  //               let headers = response.headers;
  //               let blob = new Blob([response.data],{type:headers['content-type']});
  //               let link = document.createElement('a');
  //               link.href = window.URL.createObjectURL(blob);
  //               link.download = this.ExportInboxFilesexcel;
  //               link.click();
  //               setTimeout(() => {
  //                 this.snackbardowloadonetotalexcel = false;
  //               }, 2500);
  //             }
  //         }
  //       })
  //       .catch((error) => {
  //         Toast.fire({
  //           icon: "error",
  //           title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilesexcel + " นี้ได้",
  //         });
  //         console.log(error);
  //       });
  //   },
  
    searchdata() {
      this.filemultipledownload = [];
      this.totalfile = 0;
      this.page = 1
      if (this.datatype["id"] === 1) {
        this.getInboxReceiverV2();
      } else {
        this.getInboxDataV3();
      }
    },
    clickpagination() {
      if (this.checkpagination === true) {
        this.checksortorderby();
      } else {
        // this.searchdata();
        this.filemultipledownload = [];
        // this.totalfile = 0;
        if (this.datatype["id"] === 1) {
        //this.getInboxReceiverV2();
      } else {
        this.totalfile = 0;
        this.getInboxDataV3();
      }
      }
    },
    async getInboxReceiverV2() {
      this.inboxdata = [];
      this.buff = [];
      let i;
      let type_code = this.documenttype.find(x => x === 'T05')
      if(type_code === 'T05'){
        let code_ = this.documenttype.find(x => x === 'T06')
        if(code_ !== 'T06'){
          this.documenttype.push("T06");
        }
      }else{
        let index = this.documenttype.findIndex(x => x === 'T06')
        // console.log("index",index)
        if(index != -1){
          this.documenttype.splice(index,1)
        } 
      }
      if (this.source) {
        this.source.cancel()
      }
      this.source = CancelToken.source()
      const cancelToken = this.source.token

      this.loading_company = 0;
      this.total_download = this.dataBusinessProfile.length;
      for (i = 0; i < this.dataBusinessProfile.length; i++) {
        let _payload = {
          receiver_id: this.dataBusinessProfile[i]["business_id"],
          receiver_type: "2",
          cre_dtm_start: this.formatDate(this.fromdateupload),
          cre_dtm_end: this.formatDate(this.todateupload),
          filename: this.filename,
          fileextension: this.fileextension,
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          doc_date_start: this.formatDate(this.fromdatedocument),
          doc_date_end: this.formatDate(this.todatedocument),
          doc_type_code: this.documenttype,
          system: this.$route.query.systemid,
          sender_name: this.sendername,
          receiver_name: this.receivername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
        };
        let auth = await gbfGenerate.generateToken();
        try {
          const response = await this.axios({
            method: 'POST',
            withCredentials: false,
            headers: { Authorization: auth.code },
            url: process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v2/get_all_receiver",
            cancelToken: cancelToken,
            data:_payload
          })
          this.loaddataprogress = false;
          this.loading_company +=1;
          if(this.loading_company == this.dataBusinessProfile.length){
            this.openload = false;
          }else{
            this.openload = true;
          }
          if (response.data.status === "OK") {
            // console.log('response_ok',response);
            if (response.data.result["sum_receive"] > 0) {
              this.totalfile += 1;
              let payload = {
                receiver_id: _payload["receiver_id"],
                receiver_name: response.data.result["receiver_name"],
                receiver_name_eng: response.data.result["receiver_name_eng"],
                // fullname_eng: response.data.result["fullname_eng"],
                // fullname_th: response.data.result["fullname_th"],
                sum_receive: response.data.result["sum_receive"],
                // package: response.data.result["package"],
              };
              this.inboxdata.push(payload);
              this.inboxdata.sort((a, b) => b.sum_receive - a.sum_receive);
              this.filemultipledownload = [];
              this.buff.push(payload);
              this.buff.sort((a, b) => b.sum_receive - a.sum_receive);
            }
            // this.checkloaddata = false;
          } else {
            // Toast.fire({
            //   icon: "error",
            //   title: response.data.errorMessage
            // });
            // console.log("response_error",response.data);
          }
        } catch (ex) {
          // console.log(ex);
          this.loaddataprogress = false;
        }
      }
    },

    getInboxDataV3() {
      this.openload = false
      this.inboxdata = [];
      this.buff = [];
      let business = [];
      
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        business.push(this.dataBusinessProfile[i]["business_id"]);
      }
      this.loadDataInboxV3(business, "2");
    },
    async loadDataInboxV3(_accountid, _type) {
      this.checkloaddata = true;
      if(this.statusde == 'Active'){
         this.sortStatus = 'active'
      }
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      let payload = {
        limit: this.size.toString(),
        offset: offset.toString(),
        receiver_id: _accountid,
        receiver_type: _type,
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status: this.sortStatus,
        refund_status: this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",
        sort_by: this.sort_by,
        direction: this.direction,
      };
      if (this.source) {
        this.source.cancel()
      }
      this.source = CancelToken.source()
      const cancelToken = this.source.token

      let auth = await gbfGenerate.generateToken();
      try {
        const res = await this.axios({
          method: 'POST',
          headers: { Authorization: auth.code },
          url: process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v3/get_file_inbox",
          cancelToken: cancelToken,
          data:payload
        })
        if (res.data.status === "OK") {
          // this.inboxdata = [];
          if ("total_file" in res["data"]["result"]) {
            this.totalfile = res.data.result["total_file"] || 0;
            //   for(let i=0;i < res.data.result.data.length ; i++){
              //   if(res.data.result.data[i].relation_status === 'True'){
                //     console.log(res.data.result.data[i]);
            //     this.inboxdata.push(res.data.result.data[i]);
            //     console.log(this.inboxdata);
            //   }
            // }
            this.inboxdata = res.data.result["data"];
            this.buff = res.data.result["data"];
          } else {
            this.totalfile = 0;
            this.inboxdata = [];
            this.buff = [];
          }
          this.checkloaddata = false;
          this.loaddataprogress = false;
        } else {
          this.checkloaddata = false;
          this.loaddataprogress = false;
          this.totalfile = 0;
        }
      }catch(error){
        this.checkloaddata = false;
        this.loaddataprogress = false;
        this.totalfile = 0;
        console.log(error);
      }
    },
   async multipledownloadbycompany() {
      this.percentCompleted = 0;
      this.percent = 0;
      // this.$store.dispatch("show_loading", true);
      let receiver = [];
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        receiver.push(this.filemultipledownload[i]["receiver_id"]);
      }
      let payload = {
        receiver_id: receiver,
        receiver_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
      };
      // this.snackbardowload = true;
      this.btsdownload = true;
      this.opensnackbarmultidownload = true;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
         onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.namedowload = "InboxFilesByCompany.zip"
              // console.log(progresspercent);
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
        }
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
            "/api/multiple_receiver_downloads_byfilter",
          payload,config
        )
        .then((response) => {
          this.statusmutipledownload = false;
          console.log(response);
          if (response.statusText === "OK") {
            if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                console.log("responseerror", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {

                this.percentCompleted = 100;
                this.btsdownload = false;
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, this.InboxFilesByCompany);
                let headers = response.headers;
                let blob = new Blob([response.data],{type:headers['content-type']});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = this.InboxFilesByCompany;
                link.click();
                setTimeout(() => {
                  this.opensnackbarmultidownload = false
                  }, 2500);
              }
          }
          this.clearmultipledownload();
        })
        .catch((error) => {
          this.btsdownload = false;
          this.opensnackbarmultidownload = true;
          Toast.fire({
            icon: "error",
            title: this.$t("myinboxPage.msg.apierror"),
          });
          console.log(error);
        });
    },
    // multipledownloadbycompany() {
    //   this.$store.dispatch("show_loading", true);
    //   let receiver = [];
    //   for (let i = 0; i < this.filemultipledownload.length; i++) {
    //     receiver.push(this.filemultipledownload[i]["receiver_id"]);
    //   }
    //   let payload = {
    //     receiver_id: receiver,
    //     receiver_type: "2",
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.sourcesystem,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid
    //   };
    //   this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
    //         "/api/multiple_receiver_downloads_byfilter",
    //       payload,
    //       {
    //         withCredentials: false,
    //         responseType: "blob"
    //       }
    //     )
    //     .then(response => {
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "InboxFilesByCompany.zip");
    //       document.body.appendChild(link);
    //       link.click();

    //       this.$store.dispatch("show_loading", false);
    //     })
    //     .catch(error => {
    //       this.$store.dispatch("show_loading", false);
    //       console.log(error);
    //     });
    // },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
   async multipledownloadbyfiles() {
        this.snackbars=[];
        let filedata = [];
        this.percentCompleted = 0;
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          filedata.push(this.filemultipledownload[i]["inbox_id"]);
        console.log("++",this.filemultipledownload[i]);
        }
        console.log(filedata);

        let payload = {
          // list_file: filedata,
          account_id: this.dataAccountId,
          data_type:"inbox",
          inbox_id:filedata
        };
        this.btsdownload = true;
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();
        let config = {headers: { Authorization: auth.code },
        };
        await this.axios
          // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/multiple_downloads_file", payload, config)
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/prepare_zip_file_inbox", payload, config)
          .then((response) => {
            this.statusmutipledownload = false;

            console.log(response);
            if (response.statusText === "OK") {
              console.log("response OK",response);
              this.fn_loop_multidownloading(response.data.all_file, response.data.zip_name, response.data.url);
            } else {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            this.clearmultipledownload();
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
      // this.percentCompleted = 0;
      // let filedata = [];
      // for (let i = 0; i < this.filemultipledownload.length; i++) {
      //   filedata.push(this.filemultipledownload[i]["file_id"]);
      // }
      // let payload = {
      //   list_file: filedata,
      //   account_id: this.dataAccountId,
      // };
      // this.btsdownload = true;
      // this.opensnackbarmultidownload = true;
      // // this.snackbardowload = true;
      // let auth = await gbfGenerate.generateToken();
      // let config = {
      //   headers: { Authorization: auth.code},
      //   onDownloadProgress: (progressEvent) => {
      //         let progresspercent = Math.round(
      //           (progressEvent.loaded * 100) / progressEvent.total
      //         );
      //         this.namedowload = "InboxFile.zip";
      //         // console.log(progresspercent);
      //         if (progresspercent === 95) {
      //           this.percentCompleted = 95;
      //         } else {
      //           this.percentCompleted = progresspercent;
      //         }
      //         this.percent = this.percentCompleted 
      //       },
      //       withCredentials: false,
      //       responseType: "arraybuffer",
      //   }
      // await this.axios
      //   .post(
      //     process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
      //       "/api/multiple_downloads_file",
      //     payload,config
      //   )
      //   .then((response) => {
      //     this.statusmutipledownload = false;
      //     console.log(response);
      //     if (response.statusText === "OK") {
      //       if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
      //           console.log("response < 1000", response);
      //           this.opensnackbarmultidownload = false;
      //           let res_error = gbfdecodearraybuffer.decodearraybuffer(
      //             response.data
      //           );
      //           console.log("responseerror", res_error);
      //           Toast.fire({
      //             icon: "error",
      //             title: res_error.errorMessage,
      //           });
      //         } else {
      //           this.percentCompleted = 100;
      //           this.btsdownload = false;
      //           // const blob = new Blob([response.data]);
      //           // const content = response.headers["content-type"];
      //           // saveAs(blob, "InboxFile.zip");
      //           let headers = response.headers;
      //           let blob = new Blob([response.data],{type:headers['content-type']});
      //           let link = document.createElement('a');
      //           link.href = window.URL.createObjectURL(blob);
      //           link.download = "InboxFile";
      //           link.click();
      //             setTimeout(() => {
      //               this.opensnackbarmultidownload = false
      //               }, 2500);
      //         }
      //     }

      //     this.clearmultipledownload();
      //   })
      //   .catch((error) => {
      //     this.btsdownload = false;
      //     this.opensnackbarmultidownload = false;
      //     Toast.fire({
      //       icon: "error",
      //       title: this.$t("myinboxPage.msg.apierror"),
      //     });
      //     console.log(error);
      //   });
    },
    // multipledownloadbyfiles() {
    //   // this.$store.dispatch("show_loading", true);
    //   let filedata = [];
    //   for (let i = 0; i < this.filemultipledownload.length; i++) {
    //     filedata.push(this.filemultipledownload[i]["file_id"]);
    //   }

    //   let payload = {
    //     list_file: filedata,
    //     account_id: this.dataAccountId
    //   };

    //   this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
    //         "/api/multiple_downloads_file",
    //       payload,
    //       {
    //         withCredentials: false,
    //         responseType: "blob"
    //       }
    //     )
    //     .then(response => {
    //       this.statusmutipledownload = false;
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "InboxFile.zip");
    //       document.body.appendChild(link);
    //       link.click();
    //       this.$store.dispatch("show_loading", false);
    //       this.clearmultipledownload();
    //     })
    //     .catch(error => {
    //       this.$store.dispatch("show_loading", false);
    //       Toast.fire({
    //         icon: "error",
    //         title: this.$t("myinboxPage.msg.apierror")
    //       });
    //       console.log(error);
    //     });
    // },

   multipledownload() {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          type: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        if (this.datatype["id"] === 1) {
          this.multipledownloadbycompany();
        } else {
          this.multipledownloadbyfiles();
        }
      }
    },
    gotosourcesystem(_business) {
      console.log("testtttt");

      this.$router.push({
        path: "/myinbox-sourcesystem",
        query: {
          type: 2,
          sourcesystem: this.$route.query.sourcesystem,
          systemid: this.$route.query.systemid,
          biz: _business["receiver_id"],
          filename: this.filename,
          fileextension: this.fileextension,
          fromdate: this.fromdateupload,
          todate: this.todateupload,
          fromdatedoc: this.fromdatedocument,
          todatedoc: this.todatedocument,
          nameen: _business["receiver_name_eng"],
          nameth: _business["receiver_name"],
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
        },
      });
    },
    fn_setheader(type){
      console.log(type);
      this.typedownload = type
       this.opendialogselectheader = true;
    },
    checkdownloadcsvandexcel(item){
      this.listheader = [];
      console.log("item",item);
      for(let i = 0 ; i < item.length; i++){
        if(item[i].select !== null){
          this.listheader.push(item[i].header_name)
        }
      }
      console.log("listheader",this.listheader);
      if(this.typedownload === 'csv'){
        this.exportfilecsv();
      }else{
        this.exportfileexcel();
      }

    }
   
  },
  mounted() {
     console.log("dataBusinessProfile",this.dataBusinessProfile.length);
  
    // this.fromdateupload = this.$route.query.fromdate;
    // this.todateupload = this.$route.query.todate;

    // this.fromdatedocument = this.$route.query.fromdate;
    // this.todatedocument = this.$route.query.todate;
    this.searchdata();
    // this.getSourceSystem();
    this.getDataFilters();
    //   console.log(this.$router.app['name'])
  },
};
</script>

<style scoped>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainer_allbusinessinboxsystem {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}
</style>